import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavComponent} from './nav/nav.component';
import {FooterComponent} from './footer/footer.component';
import {RouterModule} from '@angular/router';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {CartModalComponent} from './cart-modal/cart-modal.component';

@NgModule({
  declarations: [
    NavComponent,
    FooterComponent,
    CartModalComponent
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    RouterModule
  ],
  exports: [
    NavComponent,
    FooterComponent,
    CartModalComponent
  ]
})
export class PartsModule {
}
