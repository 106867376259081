import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {SecurityService} from '../modules/login/security.service';

@Injectable({
  providedIn: 'root'
})
export class OnlyAnonymeGuard implements CanActivate {
    constructor(private security: SecurityService,
                private route: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree>
        | boolean | UrlTree {
        if (!this.security.isLoggedIn()) {
            return true;
        }
        this.route.navigateByUrl('/', {});
  }
}

