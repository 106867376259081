import {Component, OnInit, HostListener, ViewChild, ElementRef} from '@angular/core';
import {ShoppingCartService} from '../../cart/shopping-cart.service';
import {getProductImageUrl} from '../../shop/utils/getProductImageUrl';

@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.component.html'
})
export class CartModalComponent implements OnInit {

  products: {};
  totalItems: number;
  priceTotal: number;
  show = false;
  @ViewChild('cart', {static: false}) cart: ElementRef;
  @ViewChild('cartModal', {static: false}) cartModal: ElementRef;
  getProductImageUrl = getProductImageUrl;

  constructor(private shoppingCart: ShoppingCartService) { }

  ngOnInit(): void {
    this.shoppingCart.productList.subscribe((products) => {
      this.products = products;
      this.setTotals();
    });
  }

  setTotals(): void {
    this.totalItems = 0;
    this.priceTotal = 0;
    for (const key of Object.keys(this.products)) {
      this.totalItems += this.products[key].quantity;
      this.priceTotal += this.products[key].product.price * this.products[key].quantity;
    }
  }
  @HostListener('document:click', ['$event'])
  modal(): void {
    if (this.cart !== undefined && !this.cartModal.nativeElement.contains(event.target)) {
      this.show = false;
    }
  }
}
