import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(private http: HttpClient, private route: Router) {}

  login(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(environment.apiUrl + '/wp-json/jwt-auth/v1/token', body, httpOptions);
  }

  isLoggedIn(): boolean{
    return localStorage.getItem('bknApiToken') !== null;
  }
  logout(): any{
    localStorage.removeItem('bknApiToken');
    localStorage.removeItem('bknUser');
    return this.route.navigateByUrl('/login');
  }
}

