import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {OnlyLoggedInGuard} from './guards/only-logged-in.guard';
import {OnlyAnonymeGuard} from './guards/only-anonyme.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'account',
        loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
        canActivate: [OnlyLoggedInGuard],
      },
      {
        path: '',
        loadChildren: () => import('./modules/shop/shop.module').then(m => m.ShopModule),
        canActivate: [OnlyLoggedInGuard]
      },
      {
        path: 'product',
        loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
        canActivate: [OnlyLoggedInGuard],
      },
      {
        path: 'order',
        loadChildren: () => import('./modules/order/order.module').then(m => m.OrderModule),
        canActivate: [OnlyLoggedInGuard],
      },
      {
        path: '',
        loadChildren: () => import('./modules/cart/cart.module').then(m => m.CartModule)
      },
      {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
        canActivate: [OnlyAnonymeGuard],
      }
    ]
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
