<div class="position-relative d-inline-block cart-modal" #cartModal>
  <span class="fa fa-shopping-cart pointer" (click)="show= true;"></span>
  <span class="cartTotal position-absolute font-12">{{totalItems}}</span>
  <div class="row m-0 position-absolute cart" *ngIf="show" #cart>
    <div class="col-12 text-center" *ngIf="!totalItems">
      Aucun produit dans le panier.
    </div>
    <div class="col-12 p-0" *ngIf="totalItems">
      <div class="products scroll">
        <div class="row m-0 border-bottom" *ngFor="let item of products | keyvalue">
          <div class="col-3 p-1 p-md-3">
            <img src="{{getProductImageUrl(item.value.product)}}" class="img img-fluid mb-3">
          </div>
          <div class="col-9 pt-1 pt-md-3">
            <h5 class="mb-2">{{item.value.product.name}}</h5>
            <span>
              {{item.value.quantity}} x <span class="text-secondary">{{item.value.product.price}}Dhs</span>
            </span>
          </div>
        </div>
      </div>

      <div class="row m-0 mt-3">
        <div class="col-5 ">
          <p class="m-0 text-uppercase font-bold">total:</p>
        </div>
        <div class="col-7 text-right">
          <span>{{priceTotal}}Dhs</span>
        </div>
      </div>
      <div class="row m-0 mt-3 font-12">
        <div class="col-12 p-0">
          <a routerLink="/cart" class="btn btn-secondary d-block" (click)="show = false">Panier</a>
        </div>
        <div class="col-12 p-0 mt-2">
          <a routerLink="/checkout" class="btn btn-secondary d-block" (click)="show = false">Paiement</a>
        </div>
      </div>
    </div>
  </div>
</div>
