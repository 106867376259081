import { Component, OnInit } from '@angular/core';
import {SecurityService} from '../../login/security.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html'
})
export class NavComponent implements OnInit {

  constructor(public security: SecurityService) { }

  ngOnInit(): void {
  }

}
