import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  private products = {};
  private productSubject$ = new Subject();
  productList = this.productSubject$.asObservable();
  constructor() {
    if (sessionStorage.getItem('products')){
      this.products = JSON.parse(sessionStorage.getItem('products'));
      setTimeout(() => {
        this.productSubject$.next(this.products);
      }, 10);
    }
  }

  addProduct(product: any, quantity = 1): void{
    if (!this.products[product.id]){
      this.products[product.id] = { product: product, quantity: quantity};
    } else {
      this.products[product.id].quantity += quantity;
    }
    sessionStorage.setItem('products', JSON.stringify(this.products));
    this.productSubject$.next(this.products);
  }
  deleteProduct(product: any): void{
    if (this.products[product.id].quantity === 1){
      // delete this.products[product.id];
    } else {
      this.products[product.id].quantity--;
    }
    Object.keys(this.products).length > 0 ? sessionStorage.setItem('products', JSON.stringify(this.products)) : sessionStorage.removeItem('products');
    this.productSubject$.next(this.products);
  }
  delete(id: any): void{
    delete this.products[id];
    Object.keys(this.products).length > 0 ? sessionStorage.setItem('products', JSON.stringify(this.products)) : sessionStorage.removeItem('products');
    this.productSubject$.next(this.products);
  }
  reset(): void {
    sessionStorage.removeItem('products');
    this.products = {};
    this.productSubject$.next({});
  }
}
