import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SecurityService} from '../modules/login/security.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private authToken: SecurityService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if ( req.url.indexOf('wc/v3') !== -1) {
            const authReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('bknApiToken'))
            });
            return next.handle(authReq);
        }
        // send cloned request with header to the next handler.
        return next.handle(req);
    }
}

