<div class="header-top d-none d-md-block">
  <div class="container-fluid">
    <div class="row align-items-center m-0">
      <ul class="ml-auto list-unstyled m-0">
        <li *ngIf="security.isLoggedIn()">
          <a routerLink="/account" class="mr-2"><img src="assets/feather/user.svg" class="svg"/> mon compte</a>
          <span (click)="security.logout()" class="pointer">
              <img src="assets/feather/log-out.svg" class="svg"/>
            </span>
        </li>
        <li *ngIf="!security.isLoggedIn()"><a routerLink="/login"><img src="assets/feather/user.svg" class="svg"/>
          Connexion</a></li>
      </ul>
    </div>
  </div>
</div>
<nav class="navbar navbar-expand-lg">
  <div class="container-fluid" ngbDropdown>
    <a class="navbar-brand font-montserrat-bold text-black pl-3 font-24" routerLink="/">YAKOOTA</a>
    <div class="d-inline-block ml-2 d-lg-none ml-auto mr-3" *ngIf="security.isLoggedIn()">
      <app-cart-modal></app-cart-modal>
    </div>
    <button class="navbar-toggler" type="button" ngbDropdownToggle id="menu">
      <i class="fa fa-bars"></i>
    </button>

    <div class="collapse navbar-collapse ">
      <div class="d-flex ml-auto align-items-center" *ngIf="security.isLoggedIn()">
        <app-cart-modal class="d-inline-block"></app-cart-modal>
      </div>
    </div>
    <div ngbDropdownMenu aria-labelledby="menu">
      <ul class="navbar-nav mb-2 mb-lg-0">
        <li class="nav-item p-1 border-bottom" ngbDropdownItem *ngIf="security.isLoggedIn()">
          <a class="nav-link" routerLink="/account">Mon compte</a>
        </li>
        <li class="nav-item p-1" ngbDropdownItem>
          <a class="nav-link pointer" (click)="security.logout()" *ngIf="security.isLoggedIn()">Deconnexion</a>
          <a class="nav-link pointer" routerLink="/login" *ngIf="!security.isLoggedIn()">Connexion</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
